<template>
  <div class="q-ml-xl q-mr-lg ">
    <div class="text-center q-my-xl">
    </div>
    <div class="row	flex-left q-pr-xl q-pl-xl q-pt-xl">
      <div style="border-radius: 10px; background: #000000;"
        class="col-12 col-md-3 col-sm-6 text-center  q-pa-sm q-mb-lg">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card logo-inicio"
          style="border-radius: 10px;  width: 300px; height: 70px;">
        </span>
      </div>
    </div>

    <div class="row	flex-center q-pr-xl q-pl-xl  q-pt-xl">
      <div class="col-12 col-md-1 col-sm-6 text-center q-pb-xl  q-mx-xl ">
        <span class="q-pa-lg bg-dark shadow-6 mb-3 inline-block surface-card efecto"
          style="border-radius: 10px; cursor: pointer; width: 160px;" @click="irPacientes()">
          <q-icon style="color: aliceblue;font-size: 8em;">
            <img src="..\assets\paciente_blanco.png" alt="Icon" width="84" height="24" />
          </q-icon>
          <div class="text-h6 letras-hover q-mt-sm text-weight-bolder" style="color: aliceblue;">Pacientes</div>
        </span>
      </div>

      <div class="col-12 col-md-1 col-sm-6 text-center q-pb-xl  q-mx-xl">
        <span class="q-pa-lg bg-dark shadow-6 mb-3 inline-block surface-card efecto"
          style="border-radius: 10px; cursor: pointer; width: 160px;" @click="irConsultas()">
          <q-icon style="color: aliceblue;font-size: 8em;">
            <img src="..\assets\icono_consulta_blanco.png" alt="Icon" width="24" height="24" />
          </q-icon>
          <div class="text-h6 letras-hover q-mt-sm text-weight-bolder" style="color: aliceblue;">Consulta</div>
        </span>
      </div>

      <div class="col-12 col-md-1 col-sm-6 text-center q-pb-xl  q-mx-xl">
        <span class="q-pa-lg bg-dark shadow-6 mb-3 inline-block surface-card efecto"
          style="border-radius: 10px; cursor: pointer; width: 160px;" @click="irAgenda()">
          <q-icon style="color: aliceblue;font-size: 8em;">
            <img src="..\assets\icono_agenda_blanco.png" alt="Icon" width="24" height="24" />
          </q-icon>
          <div class="text-h6 letras-hover q-mt-sm text-weight-bolder" style="color: aliceblue;">Agenda</div>
        </span>
      </div>

      <div class="col-12 col-md-1 col-sm-6 text-center q-pb-xl  q-mx-xl">
        <span class="q-pa-lg bg-dark shadow-6 inline-block surface-card efecto"
          style="border-radius: 10px; cursor: pointer; width: 160px;" @click="irConsiltorios()">
          <q-icon style="color: aliceblue;font-size: 8em;">
            <img src="..\assets\icono_consultorios.png" alt="Icon" width="24" height="24" />
          </q-icon>
          <div class="text-h6  q-mt-sm text-weight-bolder letras-hover">Consultorio</div>
        </span>
      </div>

      <div class="col-12 col-md-1 col-sm-6 text-center q-pb-xl  q-mx-xl">
        <span class="q-pa-lg bg-dark shadow-6 mb-3 inline-block surface-card efecto"
          style="border-radius: 10px; cursor: pointer; width: 160px;" @click="irConstanza()">
          <q-icon style="color: aliceblue;font-size: 8em;">
            <img src="..\assets\icono_constanza_blanco.png" alt="Icon" width="24" height="24" />
          </q-icon>
          <div class="text-h6 letras-hover q-mt-sm text-weight-bolder" style="color: aliceblue;">Constanza</div>
        </span>
      </div>




    </div>

  </div>
</template>

<script>
import axios from 'axios';
import MyCustomIcon from '../assets/ejemplo.svg'

export default {
  data() {
    return {

    };
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
  },
  methods: {
    irPacientes() {
      this.$router.push({ name: 'ListaPacientes' });
    },
    irConsultas() {
      this.$router.push({ name: 'ListaConsultas' });
    },
    irAgenda() {
      this.$router.push({ name: 'Agenda' });
    },
    irConstanza() {
      this.$router.push({ name: 'Constanza' });
    },
    irConsiltorios() {
      this.$router.push({ name: 'ListaConsultorios' });
    }
  }
};
</script>

<style>
.logo-home {
  max-width: 35%;
  height: auto;
}

.main-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
}

.menu-option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-inicio {
  background-image: url('../assets/logo_2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}

.logo-inicio-negro {
  background-image: url('../assets/logo_black.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}

.efecto-2:hover {
  color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0 4px 16px rgba(49, 138, 172, 1);
  transition: all 0.2s ease;
}

.efecto:hover {
  border: 1px solid white;
  box-shadow: inset 0 0 20px rgba(49, 138, 172, 1), 0 0 20px rgba(49, 138, 172, 1);
  outline-offset: 15px;
  outline-color: rgba(255, 255, 255, 0);
  text-shadow: 1px 1px 2px #427388;
}

.efecto:hover .letras-hover {
  text-shadow: 0 0 0.1em rgb(119, 241, 255), 0 0 0.1em rgb(119, 250, 255),
    0 0 0.2em #87F
}

.element-linea {
  height: 5px;
  /* Altura de la línea */
  background-image: linear-gradient(to right, #42a5f5, black);
  width: 100%;
  /* Ancho de la línea */
  margin-top: 10px;
  /* Espacio entre el título y la línea (opcional) */
}

.doc-h2 {
  margin-bottom: 0px;
  line-height: 2rem;
  font-weight: 600;
  color: #42a5f5;
  font-size: 25px;
}


.btn-estilos {
  background: #1A4161;

}

.q-cards {
  text-align: center;
  background-color: #DDE9FE;
  /* Ajusta el color de fondo */
  color: #424242;
  /* Color del texto */
  border-radius: 10px;
  /* Agregar un poco de borde redondeado */
}

.q-cards-dark {
  text-align: center;
  background-color: #1D1D1D;
  /* Ajusta el color de fondo */
  color: #1D1D1D;
  /* Color del texto */
  border-radius: 10px;
  /* Agregar un poco de borde redondeado */
}

.text-subtitle1-dark {
  color: #1A4161;
  /* Color para el subtítulo */
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 8px;
  /* Espacio entre el subtítulo y el número */
}

.text-h4-dark span {
  color: #42a5f5;
  /* Color para el número */
  font-size: 3rem;
  font-weight: 800;
  /* Grosor del número */
}

.text-subtitle1 {
  color: #42a5f5;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.text-h4 span {
  color: #1A4161;
  /* Color para el número */
  font-size: 3rem;
  font-weight: 800;
  /* Grosor del número */
}
</style>