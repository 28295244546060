<template>
    <div class="q-pa-md">
        <!-- PARA AGREGAR UN NUEVO CONCEPTO DE PAGO -->
        <q-dialog v-model="dialogConcepto" transition-show="scale" transition-hide="scale">
            <q-card style="min-width: 800px">
                <q-card-section>
                    <div class="row">
                        <div class="col text-left">
                            <q-btn round style="background:#1A4161; color:white" icon="mdi-close"
                                @click="dialogConcepto = false" />
                            <span class="text-h6">
                                Concepto
                            </span>
                        </div>
                    </div>
                    <div class="element-linea"></div>
                </q-card-section>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-6">
                            <q-input filled v-model="cobro.fecha" label="Fecha de consulta" dense readonly />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input filled v-model="cobro.paciente" label="Paciente" dense readonly />
                        </div>
                        <div class="col-12">
                            <q-input filled v-model="cobro.descripcion" label="Concepto" dense />
                        </div>
                        <div class="col-12">
                            <q-input filled input-class="text-right" v-model="cobro.precio" label="Precio" dense
                                type="number" @keydown.enter="pushConcepto()" />
                        </div>
                    </div>
                </q-card-section>
                <q-separator />
                <q-card-actions vertical>
                    <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                        style="background:#1A4161; color:white" @click="pushConcepto()">
                        Agregar concepto
                    </q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- INDICAR EL METODO DE PAGO -->
        <q-dialog v-model="dialogMetodoPago" transition-show="scale" transition-hide="scale">
            <q-card style="min-width: 800px">
                <q-card-section>
                    <div class="row">
                        <div class="col text-left">
                            <q-btn round style="background:#1A4161; color:white" icon="mdi-close"
                                @click="dialogMetodoPago = false" />
                            <span class="text-h6">
                                Método de pago
                            </span>
                        </div>
                    </div>
                    <div class="element-linea"></div>
                </q-card-section>
                <q-card-section>
                    <div class="q-gutter-xs">
                        <q-btn style="background:#1A4161; color:white"
                            @click="referencia = 'Efectivo'; formaPago = 'Efectivo'; PostPago()">
                            <q-icon left size="3em" name="mdi-cash" />
                            <div>Efectivo</div>
                        </q-btn>
                        <q-btn style="background:#1A4161; color:white"
                            @click="OpenDialogReferencia(); formaPago = 'Transferencia'">
                            <q-icon left size="3em" name="mdi-swap-horizontal-bold" />
                            <div>Transferencia</div>
                        </q-btn>
                        <q-btn style="background:#1A4161; color:white"
                            @click="OpenDialogReferencia(); formaPago = 'Cheque'">
                            <q-icon left size="3em" name="mdi-file-sign" />
                            <div>Cheque</div>
                        </q-btn>
                        <q-btn style="background:#1A4161; color:white"
                            @click="OpenDialogReferencia(); formaPago = 'Tarjeta de crédito'">
                            <q-icon left size="3em" name="mdi-credit-card" />
                            <div>Tarjeta de débito</div>
                        </q-btn>
                        <q-btn style="background:#1A4161; color:white"
                            @click="OpenDialogReferencia(); formaPago = 'Tarjeta de débito'">
                            <q-icon left size="3em" name="mdi-credit-card-chip" />
                            <div>Tarjeta de crédito</div>
                        </q-btn>
                        <q-btn style="background:#1A4161; color:white">
                            <q-icon left size="3em" name="mdi-qrcode" />
                            <div>CoDi</div>
                        </q-btn>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- INDICA LA REFERENCIA DEL PAGO -->
        <q-dialog v-model="dialogReferencia" transition-show="scale" transition-hide="scale">
            <q-card style="min-width: 800px">
                <q-card-section>
                    <div class="row">
                        <div class="col text-left">
                            <q-btn round style="background:#1A4161; color:white" icon="mdi-close"
                                @click="dialogReferencia = false" />
                            <span class="text-h6">
                                Referencia de pago
                            </span>
                        </div>
                    </div>
                    <div class="element-linea"></div>
                </q-card-section>
                <q-card-section>
                    <div class="q-gutter-xs">
                        <div class="col-12">
                            <q-input filled v-model="referencia" label="Referencia" dense />
                        </div>
                        <div class="col-12">
                            <div class="text-h2">
                                Total: {{ total }}
                            </div>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions vertical>
                    <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                        style="background:#1A4161; color:white" @click="PostPago()">
                        Confirmar pago
                    </q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-card class="full-width">
            <q-card-section>
                <q-toolbar>
                    <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close" dense
                        @click="CloseDialogcobro()">
                        <q-tooltip>
                            Cerrar
                        </q-tooltip>
                    </q-btn>
                    <q-toolbar-title>
                        Cobro
                    </q-toolbar-title>
                </q-toolbar>
                <div class="element-linea"></div>
            </q-card-section>
            <q-card-section>
                <q-table title="Cocenptos de cobro" :data="listConceptos" :columns="columnsConceptos" row-key="nombre"
                    :pagination="pagination" dense>
                    <template v-slot:top-right>
                        <q-btn size="md" class="btn-estilos q-mr-sm" icon="mdi-plus" unelevated rounded
                            style="background:#1A4161; color:white" label="Agregar concepto" @click="nuevoConcepto()" />
                    </template>
                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td auto-width key="acciones">
                                <q-btn size="md" style="color:#686868" rounded flat dense
                                    @click="deleteConcepto(props.row)" icon="mdi-delete">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Eliminar</q-tooltip>
                                </q-btn>
                            </q-td>
                            <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                            <q-td key="precio" :props="props">{{ props.row.precio }}</q-td>
                        </q-tr>
                    </template>
                </q-table>
            </q-card-section>
            <q-card-actions vertical align="right">
                <div class="col-12">
                    <q-input filled input-class="text-right" v-model="total" label="Total" dense readonly />
                </div>
            </q-card-actions>
            <q-card-actions vertical>
                <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                    style="background:#1A4161; color:white" @click="dialogMetodoPago = true">
                    Indicar método de pago
                </q-btn>
            </q-card-actions>
        </q-card>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import { date } from 'quasar'
import moment from 'moment';

export default {
    components: {

    },
    data() {
        return {
            dialogConcepto: true,
            listConceptos: [],
            columnsConceptos: [
                { name: "actions", align: "center", label: "Acciones", field: "actions", },
                { name: "descripcion", align: "left", label: "Descripción", field: "descripcion", sortable: true, },
                { name: "precio", align: "right", label: "Precio", field: "precio", sortable: true, },
            ],
            pagination: {
                descending: false,
                page: 1,
                rowsPerPage: 10,
            },
            total: 0,
            dialogMetodoPago: false,
            dialogReferencia: false,
            referencia: "",
            formaPago: "",

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        cobro() {
            return this.$store.state.cobrosStore;
        },

    },

    watch: {
    },

    created() {

    },

    methods: {
        async PostCobro() { },

        CloseDialogcobro() {
            this.$emit("cierraCobro");
        },

        nuevoConcepto() {
            this.dialogConcepto = true;
        },

        pushConcepto() {
            let c = { ...this.cobro }
            let cDefault = {
                item: 0,
                precio_unitario: 0,
                precio: 0,
                fecha: c.fecha,
                id_consulta: c.id_consulta,
                id_consultorio: c.id_consultorio,
                id_medico: c.id_medico,
                id_paciente: c.id_paciente,
                medico: c.medico,
                clave: "",
                descripcion: "",
                cantidad: 1,
                medida: "",
                paciente: c.paciente,
                telefono: c.telefono,
            }
            if (c.precio == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'El precio debe ser diferente a 0.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }
            c.precio_unitario = parseFloat(c.precio_unitario);
            c.precio = parseFloat(c.precio);
            this.listConceptos.push(c)
            this.$store.state.cobrosStore = { ...cDefault }
            var cont = 1;
            for (let x of this.listConceptos) {
                x.item = cont;
                cont++;
            }
            this.calculaTotal();
        },

        deleteConcepto(item) {
            let indice = this.listConceptos.findIndex(x => x.item === item.item)
            this.listConceptos.splice(indice, 1);
            this.calculaTotal();
        },

        calculaTotal() {
            const totalSum = this.listConceptos.reduce((accumulator, currentItem) => accumulator + currentItem.precio, 0);
            this.total = totalSum;
        },

        OpenDialogReferencia() {
            this.dialogReferencia = true;
            this.referencia = "";
        },

        async PostPago() {
            try {
                if (this.referencia === "") {
                    this.$q.notify({
                        type: 'warning',
                        message: "Indique la referncia de pago, como lo es número de transferencia, número de cheque o los últimos 4 dígitos de la tarjeta.",
                        actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                    })
                    return;
                }
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Generando Cancelación...', messageColor: 'white' })

                const ObjPago = {
                    fecha_pago: new Date(),
                    forma_pago: this.formaPago,
                    total: this.total,
                    referencia: this.referencia,
                    conceptos: this.listConceptos,
                    id_usuario: this.token._id,
                    id_consulta: this.cobro.id_consulta,
                    fecha_creacion: new Date(),
                }
                console.log(ObjPago, "Vamos a hacer el pago")
                let response = await axios.post("PuntoDeVenta/PostVentaAsync/dev/", ObjPago);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive',
                    message: `Cobro guardado con éxito`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })

            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'negative',
                    message: `Error al guardar, intente nuevamente`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
            }
        },

    }
}
</script>
