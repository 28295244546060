import { render, staticRenderFns } from "./Agenda.vue?vue&type=template&id=8e3bcec0"
import script from "./Agenda.vue?vue&type=script&lang=js"
export * from "./Agenda.vue?vue&type=script&lang=js"
import style0 from "./Agenda.vue?vue&type=style&index=0&id=8e3bcec0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QTime from 'quasar/src/components/time/QTime.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QBtn,QInput,QSeparator,QCardActions,QToolbar,QTooltip,QToolbarTitle,QSelect,QEditor,QTable,QIcon,QPopupProxy,QDate,QTr,QTd,QExpansionItem,QTime});qInstall(component, 'directives', {ClosePopup});
