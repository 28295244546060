<template>
    <div class="q-pa-md">
        <q-card class="full-width">
            <q-card-section>
                <div class="row	flex-left ">
                    <!-- LOGO -->
                    <div class="col-12 col-md-6 text-left ">
                        <span v-if="$store.state.isDark == true"
                            class="q-pa-lg shadow-6 mb-3 inline-block surface-card logo-inicio"
                            style="border-radius: 10px; width: 230px; height: 50px;">
                        </span>

                        <span v-else class="q-pa-lg shadow-0 mb-3 inline-block surface-card logo-inicio-negro"
                            style=" width: 230px; height: 50px;">
                        </span>
                    </div>
                    <div class="col-12 col-md-6 text-right ">

                    </div>
                    <div class="element-linea"></div>
                </div>
            </q-card-section>
            <q-table title="Cobros" :data="listCobros" :columns="columnsCobros" row-key="nombre"
                :pagination="pagination" dense>
                <template v-slot:top-right>
                    <q-input filled v-model="fechaInicial" mask="date" :rules="['date']" dense label="Fecha inicial">
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="fechaInicial">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="OK" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>
                    </q-input>
                    <q-input filled v-model="fechaFinal" mask="date" :rules="['date']" dense label="Fecha final"
                        class="q-ml-sm">
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="fechaFinal">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="OK" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>
                    </q-input>
                    <q-btn round style="background:#1A4161; color:white" class="q-ml-sm q-mb-md" icon="mdi-magnify"
                        @click="GetCitas()" />
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="actions">
                            <template v-if="props.row.estatus != 'Cobrado'">
                                <q-btn size="md" style="color:#686868" rounded flat dense
                                    @click="deleteConcepto(props.row)" icon="mdi-currency-usd">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Cobrar</q-tooltip>
                                </q-btn>
                            </template>
                        </q-td>
                        <q-td key="fecha_consulta" :props="props">{{ FormateadoFechasDos(props.row.fecha_consulta)
                            }}</q-td>
                        <q-td key="paciente" :props="props">{{ props.row.paciente }}</q-td>
                        <q-td key="motivo_consulta" :props="props">{{ props.row.motivo_consulta }}</q-td>
                        <q-td key="total" :props="props">{{ FormatoDecimales(props.row.total) }}</q-td>
                        <q-td key="fecha_pago" :props="props">{{ FormateadoFechasDos(props.row.fecha_pago) }}</q-td>
                        <q-td key="forma_pago" :props="props">{{ props.row.forma_pago }}</q-td>
                        <q-td key="estatus" :props="props">{{ props.row.estatus }}</q-td>
                    </q-tr>
                </template>
            </q-table>
            <q-card-section>

            </q-card-section>
        </q-card>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import { date } from 'quasar'
import moment from 'moment';

export default {
    components: {

    },
    data() {
        return {
            fechaInicial: "",
            fechaFinal: "",
            columnsCobros: [
                { name: "actions", align: "center", label: "Acciones", field: "actions", },
                { name: "fecha_consulta", align: "left", label: "Fecha de consulta", field: "fecha_consulta", sortable: true, },
                { name: "paciente", align: "left", label: "Paciente", field: "paciente", sortable: true, },
                { name: "motivo_consulta", align: "left", label: "Motivo de consulta", field: "motivo_consulta", sortable: true, },
                { name: "total", align: "right", label: "Total", field: "total", sortable: true, },
                { name: "fecha_pago", align: "left", label: "Fecha de pago", field: "fecha_pago", sortable: true, },
                { name: "forma_pago", align: "left", label: "Forma de pago", field: "forma_pago", sortable: true, },
                { name: "estatus", align: "left", label: "Estatus", field: "estatus", sortable: true, },
            ],
            pagination: {
                // sortBy: "nombre",
                descending: false,
                page: 1,
                rowsPerPage: 10,
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        listCobros() {
            return this.$store.state.listCobrosStore;
        },

    },

    watch: {

    },

    created() {
        this.Iniciales()
    },

    methods: {
        async Iniciales() {
            await this.fechaActual();
        },

        fechaActual() {
            const timeStamp = Date.now()
            const formattedString = date.formatDate(timeStamp, 'YYYY/MM/DD');
            this.fechaInicial = formattedString;
            this.fechaFinal = formattedString;
        },

        FormateadoFechasDos(fecha) {
            moment.locale('es');
            const date = new Date(fecha);
            let formattedDate = moment(date).format('DD-MMMM-YYYY');
            return formattedDate;
        },

        FormatoDecimales(numero) {
            const numeroFormateado = numero.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            return numeroFormateado;
        },

        async GetCitas() {
            const fechaI = this.fechaInicial.replaceAll("/", "-");
            const fechaF = this.fechaFinal.replaceAll("/", "-");
            const medico = this.$store.state.idMedicoStore;
            const consultorio = this.$store.state.idConsultorioStore;
            console.log(fechaI, fechaF)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Consultando. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get('PuntoDeVenta/GetVentasAsync/dev/' + medico + '/' + consultorio + '/' + fechaI + '/' + fechaF);
                console.log(response.data)
                const x = [...response.data]
                this.$store.state.listCobrosStore = [];
                this.$store.state.listCobrosStore = [...x];
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },
    }
}
</script>